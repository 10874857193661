import {
    Container,
    Header,
    SpaceBetween,
    FormField,
    Select,
    Button,
} from '@amzn/awsui-components-react-v3';
import { ActivityGroupData } from '../../../../interfaces/activityGroup';
import { ActivityGroupSessionsTable } from './ActivityGroupSessionsTable';
import { useActivityGroupDeliveryWeekOptions } from '../hooks/useActivityGroupDeliveryWeekOptions';
import { ActivityGroupSessionValuePairs } from './ActivityGroupSessionValuePairs';
import { ActivityGroupSessionDisabledFields } from './ActivityGroupSessionDisabledFields';

export const ActivityGroupDeliveryDetails = ({
    activityGroup,
}: {
    activityGroup: ActivityGroupData;
}) => {
    const getActiveChildActivities = () =>
        activityGroup?.child_activities?.filter(
            (activity) => activity.activity_status !== 'Canceled',
        );
    const activeChildActivities = getActiveChildActivities();
    const { weekOptionsForDates, selectedWeek, handleWeekChange } =
        useActivityGroupDeliveryWeekOptions({
            start: activityGroup.start_timestamp,
            end: activityGroup.end_timestamp,
            timezone: activityGroup.selected_timezone,
            includeAllOption: true,
        });

    if (activityGroup.name.includes('disabled')) {
        return (
            <ActivityGroupSessionDisabledFields
                activityGroup={activityGroup}
                showEdit={true}
            />
        );
    }

    if (activityGroup.name.includes('key-pair')) {
        return (
            <ActivityGroupSessionValuePairs
                activities={activeChildActivities}
                name={activityGroup.name}
            />
        );
    }

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button>Edit Details</Button>
                        </SpaceBetween>
                    }
                >
                    Delivery details
                </Header>
            }
        >
            <SpaceBetween size="l" direction="vertical">
                <FormField stretch={true} label="Week">
                    <Select
                        options={weekOptionsForDates}
                        selectedOption={selectedWeek}
                        onChange={({ detail }) =>
                            handleWeekChange(detail.selectedOption)
                        }
                    />
                </FormField>

                {/* <ActivityGroupSessionsTable
                activityGroup={activityGroup}
                selectedWeek={selectedWeek.value}
            /> */}
            </SpaceBetween>
        </Container>
    );
};
