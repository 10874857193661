/* eslint-disable */
// @ts-nocheck
import {
    Container,
    Header,
    SpaceBetween,
    StatusIndicator,
    Box,
    KeyValuePairs,
    Table,
    FormField,
    Select,
    Button,
} from '@amzn/awsui-components-react-v3';
import { useActivityGroupDeliveryWeekOptions } from '../hooks/useActivityGroupDeliveryWeekOptions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Link } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const ActivityGroupSessionValuePairs = ({ activities, name }) => {
    if (!activities || activities.length === 0) {
        return null;
    }

    const activity = activities[0];

    const firstSession = activity.delivery_sessions?.[0];
    const lastSession =
        activity.delivery_sessions?.[activity.delivery_sessions?.length - 1];

    const { weekOptionsForDates, selectedWeek, handleWeekChange } =
        useActivityGroupDeliveryWeekOptions({
            start: firstSession?.start_timestamp,
            end: lastSession?.end_timestamp,
            timezone: activity.delivery_timezone,
            includeAllOption: false,
        });

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleDateString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
        });
    };

    const formatTime = (startTimestamp, endTimestamp) => {
        const startTime = new Date(startTimestamp * 1000)
            .toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            .toLowerCase();
        const endTime = new Date(endTimestamp * 1000)
            .toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            .toLowerCase();
        return `${startTime} - ${endTime}`;
    };

    const getStatusType = (status) => {
        const statusMap = {
            Hold: 'pending',
            Active: 'success',
            Canceled: 'error',
            Tentative: 'warning',
        };
        return statusMap[status] || 'info';
    };

    const getFilteredSessions = () => {
        if (!activity.delivery_sessions) {
            return [];
        }

        if (selectedWeek.value === 'all') {
            return activity.delivery_sessions;
        }

        const weekStart = dayjs(selectedWeek.value);
        const weekEnd = weekStart.add(1, 'week');

        return activity.delivery_sessions.filter((session) => {
            const sessionDate = dayjs.unix(session.start_timestamp);
            return (
                sessionDate.isAfter(weekStart) && sessionDate.isBefore(weekEnd)
            );
        });
    };

    const keyValueItems = [
        {
            label: 'Activity Name',
            value: activity.activity_name,
        },
        {
            label: 'Status',
            value: (
                <StatusIndicator type={getStatusType(activity.activity_status)}>
                    {activity.activity_status}
                </StatusIndicator>
            ),
        },
        {
            label: 'Program',
            value: activity.program,
        },
        {
            label: 'Delivery Region',
            value: activity.delivery_region,
        },
        {
            label: 'Location',
            value: `${activity.delivery_city || ''}, ${
                activity.delivery_state || ''
            }, ${activity.delivery_country || ''}`,
        },
        {
            label: 'Time Zone',
            value: activity.delivery_timezone,
        },
        {
            label: 'Modality',
            value: activity.activity_modality,
        },
    ];

    const sessionColumnDefinitions = [
        {
            id: 'session_type',
            header: 'Session Type',
            cell: (item) => item.delivery_session_type,
            sortingField: 'delivery_session_type',
        },
        {
            id: 'date',
            header: 'Date',
            cell: (item) => formatDate(item.start_timestamp),
            sortingField: 'start_timestamp',
        },
        {
            id: 'time',
            header: 'Time',
            cell: (item) =>
                formatTime(item.start_timestamp, item.end_timestamp),
            sortingField: 'start_timestamp',
        },
        {
            id: 'platform',
            header: 'Platform',
            cell: (item) => item.v_ilt_info?.type || 'N/A',
            sortingField: 'v_ilt_info.type',
        },
    ];

    const instructorColumnDefinitions = [
        {
            id: 'name',
            header: 'Name',
            cell: (item) => (
                <Link to={`/instructors/${item.pk}`}>{item.name}</Link>
            ),
        },
        {
            id: 'role',
            header: 'Role',
            cell: (item) => item.role,
        },
        {
            id: 'email',
            header: 'Email',
            cell: (item) => item.email,
        },
        {
            id: 'type',
            header: 'Type',
            cell: (item) => item.type,
        },
        {
            id: 'location',
            header: 'Location',
            cell: (item) => item.location,
        },
    ];

    return (
        <div>
            <Container
                header={
                    <Header
                        actions={
                            <Link to={`/activities/group/${name}/edit`}>
                                <Button data-testid="EditGroupButton">
                                    Edit
                                </Button>
                            </Link>
                        }
                        variant="h2"
                    >
                        Delivery details
                    </Header>
                }
                variant="stacked"
            >
                <KeyValuePairs columns={4} items={keyValueItems} />
            </Container>

            <FormField stretch={true}>
                <Select
                    options={weekOptionsForDates}
                    selectedOption={selectedWeek}
                    onChange={({ detail }) =>
                        handleWeekChange(detail.selectedOption)
                    }
                />
            </FormField>

            <Table
                sortingDisabled
                variant="stacked"
                header={
                    <Header
                        variant="h3"
                        counter={`(${getFilteredSessions().length})`}
                    >
                        Sessions
                    </Header>
                }
                columnDefinitions={sessionColumnDefinitions}
                items={getFilteredSessions()}
            />

            <Table
                header={
                    <Header
                        variant="h3"
                        counter={`(${activity.instructors?.length || 0})`}
                    >
                        Instructors
                    </Header>
                }
                variant="stacked"
                columnDefinitions={instructorColumnDefinitions}
                items={activity.instructors || []}
            />
        </div>
    );
};

export default ActivityGroupSessionValuePairs;
