import Delivery from './Delivery';
import { ActivityGroupData } from '../../../../interfaces/activityGroup';

interface ActivityGroupSessionDisabledFieldsProps {
    activityGroup: ActivityGroupData;
    showEdit?: boolean;
}

export const ActivityGroupSessionDisabledFields = ({
    activityGroup,
    showEdit = false,
}: ActivityGroupSessionDisabledFieldsProps) => {
    console.log(
        `
    =====
    =====
    activityGroup.delivery_map
    =====
    `,
        activityGroup.delivery_map,
    );
    return (
        <div>
            {Object.keys(activityGroup.delivery_map).map((delivery) => (
                <Delivery
                    key={delivery}
                    name={delivery}
                    mode={'VIEW'}
                    showEdit={showEdit}
                />
            ))}
        </div>
    );
};

export default ActivityGroupSessionDisabledFields;
